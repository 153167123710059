<template>
    <v-card class="rounded-10" flat>
        <p class="pt-6 font-weight-bold text-center text-h5 grey--text text--darken-2">
            이용약관
        </p>
        <v-divider class="mx-4"></v-divider>
        <v-sheet v-if="$route.query.type=='read'">
            <PolicyRead title="Rule" />
        </v-sheet>
        <v-sheet v-if="$route.query.type=='write' && $store.state.type=='관리자'">
            <PolicyWrite title="Rule" />
        </v-sheet>
    </v-card>
</template>
<script>
import PolicyRead from './components/Read'
import PolicyWrite from './components/Write'

export default {
    components: {
        PolicyRead,
        PolicyWrite
    }
}
</script>